/* eslint-disable react/display-name */
import { ReactNode, Ref, forwardRef } from 'react';
import { BaseTextFieldProps } from '@mui/material/TextField';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Container from 'components/container';
import { IconButton } from '@mui/material';

export interface ITextField extends BaseTextFieldProps {
  endAdormentText?: string;
  endAdornmentIcon?: ReactNode;
  endAdomentAction?: () => void;
  hasErrorMessage?: boolean;
  inputClass?: string;
  smallLabel?: string;
  maxChars?: number;
  onlyNumbers?: boolean;
  lettersOnly?: boolean;
  errorMsg?: string | undefined;
  mt?: number;
  onChange?: (e: any) => void;
  normalize?: (value: string) => string;
  showHelperText?: boolean;
}

const TextField = forwardRef<{}, ITextField>(
  (
    {
      endAdormentText,
      endAdornmentIcon,
      endAdomentAction,
      label,
      variant,
      smallLabel,
      hasErrorMessage = false,
      error,
      helperText,
      showHelperText,
      inputClass,
      maxChars,
      onlyNumbers,
      lettersOnly,
      value,
      onChange,
      normalize,
      errorMsg,
      mt,
      sx,
      ...props
    },
    ref: Ref<any>
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value;

      if (onlyNumbers) {
        newValue = newValue.replace(/\D/g, '');
      }
      if (lettersOnly) {
        newValue = newValue.replace(/[^a-zA-Z ]/g, '');
      }
      if (maxChars) {
        newValue = newValue.slice(0, maxChars);
      }
      if (normalize) {
        newValue = normalize(newValue);
      }

      e.target.value = newValue;

      onChange?.(e);
    };
    return (
      <Container mt={mt} className="text-field-container">
        {label && <div className="text-field-container__label">{label}</div>}
        {smallLabel && (
          <div className="text-field-container__small-label">{smallLabel}</div>
        )}
        <MuiTextField
          inputRef={ref}
          autoFocus={error}
          variant={variant || 'outlined'}
          size="small"
          fullWidth
          InputProps={{
            endAdornment: (
              <>
                {endAdomentAction && endAdormentText && (
                  <button
                    className="text-field-container__end-adornment-text"
                    onClick={endAdomentAction}
                  >
                    {endAdormentText}
                  </button>
                )}
                {endAdornmentIcon && endAdomentAction && (
                  <InputAdornment position="end">
                    <IconButton onClick={endAdomentAction} edge="end">
                      {endAdornmentIcon}
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
            className: inputClass ? inputClass : undefined,
          }}
          error={error || !!errorMsg}
          helperText={errorMsg ? errorMsg : showHelperText ? helperText : undefined}
          value={value}
          sx={{
            '& .MuiFormHelperText-root': {
              color: '#b71610!important',
            },
            '& .MuiError': {
              color: '#b71610!important',
            },
            ...sx,
          }}
          {...props}
          onChange={handleChange}
        />
      </Container>
    );
  }
);

export default TextField;
